var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('Page-Header',{attrs:{"title":_vm.$t('voucher.vouchers')},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":"","color":"red","icon":"","small":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}])}),_c('v-data-table',{attrs:{"items-per-page":100,"hide-default-footer":"","headers":_vm.headers,"items":_vm.vouchers,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"item.debit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.debit,"", item.floatingPoints))+" ")]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.credit,"", item.floatingPoints))+" ")]}},{key:"item.source",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isPaidBill ? _vm.$t("voucher.paidBill") : item.notes)+" ")]}},{key:"top",fn:function(){return [_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})]},proxy:true},{key:"item.voucherType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.voucherType == 2 ? _vm.$t("voucher.receipt") : item.voucherType == 3 ? _vm.$t("voucher.payment") : _vm.$t("voucher.entry"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-2",on:{"click":function($event){return _vm.print(item.voucherGuid)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-printer-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("print"))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])],1)]}},{key:"item.voucherDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.voucherDate,"yyyy-MM-DD"))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }