<template>
    <v-dialog persistent max-width="1000px" v-model="showDialog">
        <v-card>
            <Page-Header :title="$t('voucher.vouchers')">
                <template v-slot:buttons>
                    <v-btn
                        outlined
                        color="red"
                        class="mx-2"
                        @click="close"
                        icon
                        small
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                :items-per-page="100"
                hide-default-footer
                :headers="headers"
                :items="vouchers"
                :loading="loading"
                :loading-text="$t('loading')"
            >
                <template v-slot:[`item.debit`]="{ item }">
                    {{ item.debit | currency("", item.floatingPoints) }}
                </template>
                <template v-slot:[`item.credit`]="{ item }">
                    {{ item.credit | currency("", item.floatingPoints) }}
                </template>
                <template v-slot:[`item.source`]="{ item }">
                    {{ item.isPaidBill ? $t("voucher.paidBill") : item.notes }}
                </template>
                <template v-slot:top>
                    <!--Delete popup-->
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                </template>
                <template v-slot:[`item.voucherType`]="{ item }">
                    {{
                        item.voucherType == 2
                            ? $t("voucher.receipt")
                            : item.voucherType == 3
                            ? $t("voucher.payment")
                            : $t("voucher.entry")
                    }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <div class="text-end">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    @click="print(item.voucherGuid)"
                                    class="ml-2 mr-2"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-printer-outline
                                </v-icon>
                            </template>
                            <span> {{ $t("print") }} </span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    @click="deleteItem(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                    color="red"
                                >
                                    mdi-delete-outline
                                </v-icon>
                            </template>
                            <span> {{ $t("delete") }}</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:[`item.voucherDate`]="{ item }">
                    {{ item.voucherDate | moment("yyyy-MM-DD") }}
                </template>
            </v-data-table>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";

export default {
    components: { ConfirmDialog, PageHeader },
    props: {
        billGuid: {
            type: String
        },
        showDialog: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            voucherTypes: [
                {
                    key: this.$t("voucher.payment"),
                    value: 3
                },
                {
                    key: this.$t("voucher.receipt"),
                    value: 2
                },
                {
                    key: this.$t("voucher.entry"),
                    value: 1
                }
            ],
            sourceTypes: [
                {
                    key: this.$t("customer.customer"),
                    value: 1
                },
                {
                    key: this.$t("bill.bill"),
                    value: 2
                }
            ],
            headers: [
                {
                    text: this.$t("voucher.voucherNumber"),
                    value: "voucherNumber"
                },
                {
                    text: this.$t("voucher.voucherDate"),
                    value: "voucherDate"
                },
                {
                    text: this.$t("account"),
                    value: "voucherAccount"
                },
                {
                    text: this.$t("voucher.credit"),
                    value: "credit"
                },
                {
                    text: this.$t("voucher.currency"),
                    value: "currencySymbol"
                },
                { text: "", value: "actions" }
            ],
            vouchers: [],
            editedItem: {
                voucherGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: null,
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                entries: []
            },
            defaultItem: {
                voucherGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: null,
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                entries: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.refreshData();
    },
    watch: {
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        deleteItem(item) {
            this.editedIndex = this.vouchers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.vouchers[this.editedIndex];
            axios
                .delete("Vouchers/Delete?id=" + deleteItem.voucherGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.$emit("closeVouchersDialog");
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        refreshData() {
            if (this.billGuid != null)
                axios
                    .get("Vouchers/GetBillVouchers?id=" + this.billGuid)
                    .then(response => {
                        this.vouchers = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
        },
        print(voucherGuid) {
            axios({
                url: "Vouchers/PrintVoucher",
                method: "GET",
                responseType: "blob",
                params: {
                    voucherGuid: voucherGuid
                }
            }).then(response => {
                var fileURL = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "سند.pdf");
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        }
    }
};
</script>
