<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
            <Page-Header :title="$t('bill.pay')"></Page-Header>
            <v-card-text>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        hide-details
                                        outlined
                                        dense
                                        disabled
                                        v-model="editedItem.voucherNumber"
                                        :label="$t('voucher.voucherNumber')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <template>
                                        <div>
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs
                                                    }"
                                                >
                                                    <v-text-field
                                                        hide-details
                                                        outlined
                                                        dense
                                                        :rules="rules"
                                                        v-model="
                                                            editedItem.voucherDate
                                                        "
                                                        :label="
                                                            $t(
                                                                'voucher.voucherDate'
                                                            )
                                                        "
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        editedItem.voucherDate
                                                    "
                                                    :active-picker.sync="
                                                        activePicker
                                                    "
                                                    :max="
                                                        new Date(
                                                            Date.now() -
                                                                new Date().getTimezoneOffset() *
                                                                    60000
                                                        )
                                                            .toISOString()
                                                            .substr(0, 10)
                                                    "
                                                    min="1950-01-01"
                                                    @change="saveDate"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </template>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select
                                        outlined
                                        dense
                                        hide-details
                                        persistent-hint
                                        :rules="rules"
                                        v-model="editedItem.currencyGuid"
                                        @change="currencyChange"
                                        :items="currencies"
                                        item-text="currencySymbol"
                                        item-value="currencyGuid"
                                        :label="$t('voucher.currency')"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field
                                        type="number"
                                        min="0"
                                        outlined
                                        hide-details
                                        dense
                                        :disabled="isCurrencyMain"
                                        :rules="rules"
                                        v-model="editedItem.currencyValue"
                                        :label="$t('voucher.currencyValue')"
                                    ></v-text-field>
                                </v-col>
                                <!---------------------------------->
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        :label="$t('voucher.account')"
                                        v-model="editedItem.accountGuid"
                                        outlined
                                        hide-details
                                        @change="accountChange"
                                        dense
                                        hide-selected
                                        :items="accounts"
                                        item-text="nameCode"
                                        item-value="accountGuid"
                                        :rules="rules"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        :label="$t('fiscalYear.fiscalYear')"
                                        v-model="editedItem.fiscalYearGuid"
                                        outlined
                                        hide-details
                                        dense
                                        :disabled="
                                            editedItem.voucherGuid != null &&
                                                editedItem.voucherGuid !=
                                                    '00000000-0000-0000-0000-000000000000'
                                        "
                                        hide-selected
                                        :items="fiscalYears"
                                        item-text="title"
                                        item-value="fiscalYearGuid"
                                        :rules="rules"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-textarea
                                        outlined
                                        rows="4"
                                        v-model="editedItem.notes"
                                        :label="$t('voucher.notes')"
                                    ></v-textarea
                                ></v-col>
                            </v-row>
                        </v-col>
                        <!------------------------------------------------------------------------------------>
                        <v-col cols="12">
                            <v-btn
                                color="primary"
                                outlined
                                class="mb-2"
                                dark
                                @click="addItem"
                                icon
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <v-card
                                outlined
                                class="overflow-y-auto"
                                max-height="330"
                                elevation="0"
                            >
                                <v-card-text>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, i) in entries"
                                            :key="i"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row>
                                                        <v-col cols="12" md="3">
                                                            <v-autocomplete
                                                                :label="
                                                                    $t(
                                                                        'voucher.account'
                                                                    )
                                                                "
                                                                hide-details
                                                                v-model="
                                                                    item.accountGuid
                                                                "
                                                                outlined
                                                                dense
                                                                hide-selected
                                                                :items="
                                                                    entryAccounts
                                                                "
                                                                item-text="nameCode"
                                                                item-value="accountGuid"
                                                                :rules="rules"
                                                                v-on:keyup.enter="
                                                                    addItem
                                                                "
                                                            >
                                                            </v-autocomplete>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            md="2"
                                                            v-if="
                                                                vType == 3 ||
                                                                    vType == 1
                                                            "
                                                        >
                                                            <v-text-field
                                                                type="number"
                                                                min="0"
                                                                hide-details
                                                                v-on:keyup.enter="
                                                                    addItem
                                                                "
                                                                :label="
                                                                    $t(
                                                                        'voucher.debit'
                                                                    )
                                                                "
                                                                :rules="rules"
                                                                outlined
                                                                dense
                                                                v-model="
                                                                    item.debit
                                                                "
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col
                                                            cols="12"
                                                            md="2"
                                                            v-on:keyup.enter="
                                                                addItem
                                                            "
                                                            v-if="
                                                                vType == 2 ||
                                                                    vType == 1
                                                            "
                                                        >
                                                            <v-text-field
                                                                type="number"
                                                                min="0"
                                                                :label="
                                                                    $t(
                                                                        'voucher.credit'
                                                                    )
                                                                "
                                                                hide-details
                                                                :rules="rules"
                                                                outlined
                                                                dense
                                                                v-model="
                                                                    item.credit
                                                                "
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" md="3">
                                                            <v-select
                                                                outlined
                                                                v-on:keyup.enter="
                                                                    addItem
                                                                "
                                                                dense
                                                                persistent-hint
                                                                v-model="
                                                                    item.sourceGuid
                                                                "
                                                                :items="sources"
                                                                hide-details
                                                                item-text="key"
                                                                item-value="value"
                                                                :label="
                                                                    $t(
                                                                        'voucher.source'
                                                                    )
                                                                "
                                                            ></v-select>
                                                        </v-col>
                                                        <v-col cols="12" md="4">
                                                            <v-text-field
                                                                :label="
                                                                    $t('notes')
                                                                "
                                                                v-on:keyup.enter="
                                                                    addItem
                                                                "
                                                                outlined
                                                                dense
                                                                v-model="
                                                                    item.notes
                                                                "
                                                                hide-details
                                                            >
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-tooltip top>
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @click="
                                                                removeItem(i)
                                                            "
                                                            icon
                                                            color="red lighten-1"
                                                        >
                                                            <v-icon>
                                                                mdi-delete-circle-outline
                                                            </v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>
                                                        {{ $t("remove") }}
                                                    </span>
                                                </v-tooltip>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="isInRole(28)"
                    color="primary darken-1 white--text"
                    @click="save"
                    :loading="loading"
                    class="mx-4"
                    :disabled="!valid || loading"
                    :min-width="100"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    color="red darken-2"
                    class="mx-4"
                    @click="close"
                >
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import axios from "axios";
import PageHeader from "../../components/PageHeader.vue";
export default {
    components: {
        PageHeader
    },
    props: {
        voucher: {
            type: Object
        },
        update: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            fiscalYears: [],
            billRemainingAmount: 0,
            vType: null,
            activePicker: null,
            date: null,
            menu: false,
            isCurrencyMain: false,
            valid: true,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            entryIndex: null,
            search: "",
            voucherSetting: null,
            accounts: [],
            entryAccounts: [],
            currencies: [],
            sources: [],
            entries: [],
            editedItem: {
                voucherGuid: null,
                accountGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: null,
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                billGuid: null,
                isPaidBill: false,
                entries: []
            },
            defaultItem: {
                voucherGuid: null,
                accountGuid: null,
                voucherNumber: null,
                voucherType: null,
                voucherDate: null,
                notes: null,
                currencyValue: null,
                currencyGuid: null,
                sourceGuid: null,
                sourceType: null,
                vouchersSettingId: null,
                billGuid: null,
                isPaidBill: false,
                entries: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        this.loading = true;
        this.refreshData();
    },
    watch: {
        update(val) {
            if (val) {
                this.refreshData();
            }
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
        /* "editedItem.accountGuid": {
            handler: function(val) {
                this.entryAccounts = this.accounts.filter(function(obj) {
                    return obj.accountGuid != val;
                });

                if (this.entries != null)
                    for (let i = 0; i < this.entries.length; i++) {
                        if (this.entries[i].accountGuid == val) {
                            this.entries[i].accountGuid = null;
                        }
                    }
            },
            deep: true
        }, */
        "editedItem.currencyGuid": {
            handler: function(val) {
                if (val != null || val != undefined) {
                    setTimeout(() => {
                        var x = this.currencies.filter(function(obj) {
                            return obj.currencyGuid == val;
                        });
                        this.editedItem.currencyValue = x[0].currencyValue;
                        this.isCurrencyMain = x[0].isMain;
                    }, 500);
                }
            },
            deep: true
        }
    },
    methods: {
        accountChange() {
            var val = this.editedItem.accountGuid;
            this.entryAccounts = this.accounts.filter(function(obj) {
                return obj.accountGuid != val;
            });

            if (this.entries != null)
                for (let i = 0; i < this.entries.length; i++) {
                    if (this.entries[i].accountGuid == val) {
                        this.entries[i].accountGuid = null;
                    }
                }
        },
        currencyChange() {
            var currencyGuid = this.editedItem.currencyGuid;

            var selectedCurrency = this.currencies.filter(function(obj) {
                return obj.currencyGuid == currencyGuid;
            });

            this.editedItem.currencyValue = selectedCurrency[0].currencyValue;
            this.isCurrencyMain = selectedCurrency[0].isMain;
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        addItem() {
            this.entries.push({ isHeader: false });
        },
        removeItem(index) {
            this.entryIndex = index;

            if (
                this.entries[index].entryGuid == undefined ||
                this.entries[index].entryGuid ==
                    "00000000-0000-0000-0000-000000000000"
            )
                this.entries.splice(index, 1);
            else this.dialogDelete = true;
        },
        close() {
            this.$emit("closeVoucher");
        },
        refreshData() {
            this.editedItem = this.voucher;

            this.vType = this.editedItem.voucherType;
            this.entries = this.editedItem.entries;

            //get the remaining amount of the bill
            var entry = this.entries.filter(function(obj) {
                return obj.isHeader == false;
            })[0];

            this.billRemainingAmount = entry.debit + entry.credit;

            //select the main account
            this.editedItem.accountGuid = this.entries.filter(function(obj) {
                return obj.isHeader == true;
            })[0].accountGuid;

            //remove the main header
            this.entries = this.entries.filter(function(obj) {
                return obj.isHeader == false;
            });

            //Voucher Setting
            axios
                .get(
                    "Vouchers/GetSettingsByType?type=" +
                        this.voucher.voucherType
                )
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.voucherSetting = response.data.data[0];
                        this.editedItem.vouchersSettingId = this.voucherSetting.vouchersSettingId;

                        axios
                            .post(
                                "Vouchers/GenerateVoucherNumber",
                                this.editedItem
                            )
                            .then(response => {
                                if (response.data.status == "Successful") {
                                    this.editedItem.voucherNumber =
                                        response.data.data;
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            });
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });

            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("AccountsTree/Get")
                .then(response => {
                    this.accounts = response.data.data.filter(function(obj) {
                        return (
                            obj.parentGuid !=
                            "00000000-0000-0000-0000-000000000000"
                        );
                    });

                    var accountGuid = this.editedItem.accountGuid;
                    this.entryAccounts = this.accounts.filter(function(obj) {
                        return obj.accountGuid != accountGuid;
                    });
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("FiscalYears/Get")
                .then(response => {
                    this.fiscalYears = response.data.data;

                    var activeFiscalYear = this.fiscalYears.find(
                        x => x.isActive == true
                    );

                    if (activeFiscalYear != undefined) {
                        this.editedItem.fiscalYearGuid =
                            activeFiscalYear.fiscalYearGuid;
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("Customers/Get")
                .then(response => {
                    var data = response.data.data;
                    const reshapedData = data.map(function(row) {
                        return {
                            key: row.fullName,
                            value: row.customerGuid
                        };
                    });

                    this.sources = reshapedData;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            if (this.$refs.form.validate()) {
                this.editedItem.voucherGuid =
                    "00000000-0000-0000-0000-000000000000";

                //copy the displayed entries to voucher entries
                this.editedItem.entries = [...this.entries];

                var accountGuid = this.editedItem.accountGuid;
                this.editedItem.entries.forEach(function(element) {
                    element.accountContraGuid = accountGuid;
                });

                //set the new header entry
                this.editedItem.entries.push({
                    accountGuid: this.editedItem.accountGuid,
                    accountContraGuid: null,
                    isHeader: true,
                    sourceGuid: this.editedItem.billGuid,
                    sourceType: 1
                });

                var entriesDebit = this.entries.reduce(
                    (a, b) => a + (b["debit"] || 0),
                    0
                );

                if (entriesDebit > this.billRemainingAmount) {
                    this.$toast.error(
                        this.$t("error.CannotPayMoreThanRemaining")
                    );
                    return;
                }

                if (this.editedItem.entries.length < 2) {
                    this.$toast.error(this.$t("error.NoEntriesProvided"));
                } else {
                    this.loading = true;
                    axios
                        .post("Vouchers/Save", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.loading = true;
                                this.$emit("voucherSaved");
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });

                    setTimeout(() => {
                        this.loading = false;
                    }, 2000);
                }
            }
        }
    }
};
</script>
