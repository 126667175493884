<template>
    <v-container fluid>
        <v-dialog max-width="1000px" v-model="viewVoucher"
            ><voucher-card
                :voucher="voucher"
                :update="viewVoucher"
                @closeVoucher="closeVoucher"
                @voucherSaved="voucherSaved"
            >
            </voucher-card
        ></v-dialog>
        <v-dialog max-width="400px" v-model="quantityDialog">
            <v-card>
                <v-toolbar dark color="primary">
                    <v-card-title>{{ $t("bill.uniteQuantity") }}</v-card-title>
                </v-toolbar>
                <v-container fluid
                    ><v-card-text>
                        <v-text-field
                            outlined
                            type="number"
                            min="0"
                            dense
                            v-model="unitedQuantity"
                            :label="$t('billBody.quantity')"
                            hide-details
                        ></v-text-field> </v-card-text
                ></v-container>
                <v-card-actions>
                    <v-btn @click="uniteQuantity" color="primary">
                        <v-icon>mdi-pencil</v-icon>
                        {{ $t("edit") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn color="red" @click="quantityDialog = false" text>{{
                        $t("cancel")
                    }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Save-->
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card :loading="loading">
                <Page-Header
                    :title="$t('billTypes.' + bType)"
                    icon="mdi-cash"
                ></Page-Header>
                <v-card-text class="pa-5">
                    <v-row class="mb-2">
                        <v-col cols="12" md="3">
                            <v-text-field
                                outlined
                                readonly
                                type="number"
                                min="0"
                                dense
                                v-model="editedItem.billNumber"
                                :label="$t('bill.billNumber')"
                                hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <template>
                                <div>
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-text-field
                                                hide-details
                                                outlined
                                                dense
                                                :rules="rules"
                                                v-model="editedItem.billDate"
                                                :label="$t('bill.billDate')"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="editedItem.billDate"
                                            :active-picker.sync="activePicker"
                                            :max="
                                                new Date(
                                                    Date.now() -
                                                        new Date().getTimezoneOffset() *
                                                            60000
                                                )
                                                    .toISOString()
                                                    .substr(0, 10)
                                            "
                                            min="1950-01-01"
                                            @change="saveDate"
                                        ></v-date-picker>
                                    </v-menu>
                                </div>
                            </template>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select
                                outlined
                                hide-details
                                dense
                                :disabled="!isInRole(98)"
                                persistent-hint
                                :rules="rules"
                                v-model="currencyGuid"
                                :items="currencies"
                                @change="currencyChange"
                                item-text="currencySymbol"
                                item-value="currencyGuid"
                                :label="$t('voucher.currency')"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                outlined
                                hide-details
                                :disabled="!isInRole(98)"
                                :rules="rules"
                                dense
                                type="number"
                                min="0"
                                v-model="currencyValue"
                                :label="$t('voucher.currencyValue')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-select
                                outlined
                                hide-details
                                dense
                                :disabled="!isInRole(98)"
                                persistent-hint
                                :rules="rules"
                                v-model="editedItem.stockGuid"
                                :items="stocks"
                                item-text="stockName"
                                item-value="stockGuid"
                                :label="$t('stock.stock')"
                            ></v-select>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                :label="$t('bill.payType')"
                                v-model="editedItem.payType"
                                outlined
                                hide-details
                                dense
                                :rules="rules"
                                hide-selected
                                :items="payTypes"
                                item-text="key"
                                item-value="value"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                :label="$t('customer.customer')"
                                v-model="editedItem.customerGuid"
                                outlined
                                hide-details
                                dense
                                hide-selected
                                :items="customers"
                                item-text="fullName"
                                item-value="customerGuid"
                                :rules="rules"
                                append-icon="mdi-plus"
                                @click:append="customerDialog = true"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-autocomplete
                                :label="$t('itemTemplate.itemTemplates')"
                                outlined
                                v-model="planGuid"
                                hide-details
                                dense
                                @change="changePlan"
                                hide-selected
                                :items="plans"
                                item-text="title"
                                item-value="planGuid"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-textarea
                                outlined
                                hide-details
                                dense
                                rows="2"
                                v-model="editedItem.notes"
                                :label="$t('bill.notes')"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="mt-2">
                        <v-btn
                            color="primary darken-1"
                            icon
                            class="mx-3"
                            outlined
                            :loading="loading"
                            v-if="isInRole(98)"
                            dark
                            @click="addItem"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            color="primary darken-1"
                            outlined
                            class="mx-3"
                            dark
                            @click="openQuantityDialog"
                        >
                            <v-icon>mdi-counter</v-icon>
                            {{ $t("bill.uniteQuantity") }}
                        </v-btn>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-data-table
                                :headers="headers"
                                :items="editedItem.billBodies"
                                class="myTable"
                                :loading="loading"
                                :loading-text="$t('loading')"
                                hide-default-footer
                                :height="340"
                                :disable-pagination="true"
                                @click:row="setIndex"
                            >
                                <template
                                    v-slot:[`item.actions`]="{
                                        item
                                    }"
                                >
                                    <v-tooltip top>
                                        <template
                                            v-slot:activator="{
                                                on,
                                                attrs
                                            }"
                                        >
                                            <v-icon
                                                @click="deleteBillBody(item)"
                                                v-bind="attrs"
                                                v-on="on"
                                                color="red"
                                                class="px-5"
                                            >
                                                mdi-delete-outline
                                            </v-icon>
                                        </template>
                                        <span> {{ $t("delete") }}</span>
                                    </v-tooltip>
                                </template>
                                <template
                                    v-slot:[`item.barcode`]="{
                                        item
                                    }"
                                >
                                    <v-text-field
                                        flat
                                        :disabled="!isInRole(98)"
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        solo
                                        type="number"
                                        dense
                                        :autofocus="true"
                                        v-model="item.barcode"
                                        hide-details
                                        v-on:change="itemChanged(item, 1)"
                                        v-on:keyup.enter="addItem"
                                    ></v-text-field>
                                    <span v-else>{{ item.barcode }}</span>
                                </template>
                                <template
                                    v-slot:[`item.itemGuid`]="{
                                        item
                                    }"
                                >
                                    <v-autocomplete
                                        flat
                                        solo
                                        :disabled="!isInRole(98)"
                                        hide-details
                                        dense
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        persistent-hint
                                        v-on:change="itemChanged(item, 2)"
                                        v-on:keyup.enter="addItem"
                                        :rules="rules"
                                        v-model="item.itemGuid"
                                        :items="items"
                                        item-text="itemName"
                                        item-value="itemGuid"
                                    ></v-autocomplete>
                                    <span v-else>{{
                                        getItemName(item.itemGuid)
                                    }}</span>
                                </template>
                                <template v-slot:[`item.price`]="{ item }">
                                    <v-text-field
                                        flat
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        solo
                                        :disabled="!isInRole(98)"
                                        type="number"
                                        min="0"
                                        dense
                                        v-model="item.price"
                                        v-on:keyup.enter="addItem"
                                        hide-details
                                    ></v-text-field>
                                    <span v-else>{{ item.price }}</span>
                                </template>
                                <template
                                    v-slot:[`item.quantity`]="{
                                        item
                                    }"
                                >
                                    <v-text-field
                                        flat
                                        solo
                                        type="number"
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        min="0"
                                        dense
                                        v-on:keyup.enter="addItem"
                                        v-model="item.quantity"
                                        hide-details
                                    ></v-text-field>
                                    <span v-else>{{ item.quantity }}</span>
                                </template>
                                <template
                                    v-slot:[`item.totalPrice`]="{
                                        item
                                    }"
                                >
                                    <v-text-field
                                        flat
                                        solo
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        type="number"
                                        min="0"
                                        dense
                                        v-on:keyup.enter="addItem"
                                        v-model="item.totalPrice"
                                        hide-details
                                    ></v-text-field>
                                    <span v-else>{{ item.totalPrice }}</span>
                                </template>
                                <template
                                    v-slot:[`item.discount`]="{
                                        item
                                    }"
                                >
                                    <v-text-field
                                        flat
                                        solo
                                        type="number"
                                        min="0"
                                        dense
                                        v-on:keyup.enter="addItem"
                                        v-model="item.discount"
                                        hide-details
                                    ></v-text-field>
                                </template>
                                <template v-slot:[`item.extra`]="{ item }">
                                    <v-text-field
                                        flat
                                        solo
                                        type="number"
                                        min="0"
                                        dense
                                        v-model="item.extra"
                                        v-on:keyup.enter="addItem"
                                        hide-details
                                    ></v-text-field>
                                </template>
                                <template
                                    v-slot:[`item.netPrice`]="{
                                        item
                                    }"
                                >
                                    <v-text-field
                                        flat
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        solo
                                        type="number"
                                        min="0"
                                        dense
                                        readonly
                                        v-model="item.netPrice"
                                        hide-details
                                    ></v-text-field>
                                    <span v-else>{{ item.netPrice }}</span>
                                </template>
                                <template v-slot:[`item.notes`]="{ item }">
                                    <v-text-field
                                        flat
                                        v-if="
                                            rowIndex ==
                                                editedItem.billBodies.indexOf(
                                                    item
                                                )
                                        "
                                        solo
                                        dense
                                        v-on:keyup.enter="addItem"
                                        v-model="item.notes"
                                        hide-details
                                    ></v-text-field>
                                    <span v-else>{{ item.notes }}</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <!--Summary-->
                    <v-row class="mt-2">
                        <v-col cols="12" md="3">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                hide-details
                                dense
                                type="number"
                                readonly
                                :change="totalNetCalculation()"
                                v-model="editedItem.totalAmount"
                                :label="$t('bill.totalAmount')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                hide-details
                                :disabled="!isInRole(98)"
                                dense
                                type="number"
                                min="0"
                                :rules="rules"
                                :change="totalNetCalculation()"
                                v-model="editedItem.totalDiscount"
                                :label="$t('bill.totalDiscount')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                hide-details
                                :disabled="!isInRole(98)"
                                dense
                                type="number"
                                min="0"
                                :rules="rules"
                                v-model="editedItem.totalExtra"
                                :change="totalNetCalculation()"
                                :label="$t('bill.totalExtra')"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-text-field
                                class="font-weight-black centered-input"
                                outlined
                                hide-details
                                dense
                                readonly
                                type="number"
                                v-model="editedItem.totalNet"
                                :label="$t('bill.totalNet')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-row>
                        <v-col>
                            <v-row no-gutters>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        text
                                        :loading="loading"
                                        color="primary darken-1"
                                        @click="newEntity"
                                        :min-width="100"
                                    >
                                        {{ $t("new") }}
                                    </v-btn>
                                </v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        v-if="isInRole(37)"
                                        color="primary darken-1 white--text"
                                        @click="save"
                                        :loading="loading"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon
                                            >mdi-content-save-outline</v-icon
                                        >
                                        {{ $t("save") }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        v-if="
                                            isInRole(39) &&
                                                editedItem.billGuid != null
                                        "
                                        @click="print"
                                        color="primary darken-1 white--text"
                                        text
                                        :loading="loading"
                                        :disabled="!valid"
                                        :min-width="100"
                                    >
                                        <v-icon>mdi-printer-outline</v-icon>
                                        {{ $t("print") }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-switch
                                        v-if="isInRole(37)"
                                        class="mt-0"
                                        hide-details
                                        v-model="editedItem.isLock"
                                        @change="lockBill"
                                        :disabled="editedItem.isAccept"
                                        :label="$t('bill.isLock')"
                                    ></v-switch
                                ></v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-switch
                                        v-if="isInRole(37)"
                                        class="mt-0 "
                                        hide-details
                                        @change="acceptBill"
                                        v-model="editedItem.isAccept"
                                        :label="$t('bill.isAccept')"
                                        :change="isAcceptChange()"
                                    ></v-switch
                                ></v-col>
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        v-if="
                                            editedItem.payType == 2 &&
                                                editedItem.billGuid != null &&
                                                isInRole(37)
                                        "
                                        outlined
                                        @click="pay"
                                        :loading="loading"
                                        :disabled="!valid"
                                        :color="
                                            editedItem.remainingAmount == 0
                                                ? 'green'
                                                : 'warning'
                                        "
                                        :min-width="100"
                                    >
                                        <v-icon>mdi-credit-card-outline</v-icon>
                                        {{
                                            editedItem.remainingAmount != 0
                                                ? $t("bill.pay") +
                                                  " " +
                                                  editedItem.remainingAmount
                                                : $t("payStatus.paid")
                                        }}
                                    </v-btn></v-col
                                >
                                <v-col cols="auto" class="ma-1">
                                    <v-btn
                                        v-if="
                                            editedItem.payType == 2 &&
                                                editedItem.billGuid != null &&
                                                isInRole(37)
                                        "
                                        text
                                        :loading="loading"
                                        @click="displayBillVouchers"
                                        color="primary darken-1"
                                        :min-width="100"
                                    >
                                        {{ $t("voucher.vouchers") }}
                                    </v-btn></v-col
                                >
                            </v-row>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                text
                                :loading="loading"
                                color="red"
                                class="mx-2 mt-1"
                                :disabled="
                                    editedItem.billGuid == null ||
                                        editedItem.billGuid ==
                                            '00000000-0000-0000-0000-000000000000'
                                "
                                v-if="isInRole(38)"
                                @click="deleteItem()"
                                :min-width="100"
                            >
                                <v-icon>mdi-delete-outline</v-icon>
                                {{ $t("delete") }}
                            </v-btn></v-col
                        >
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-form>
        <!--Delete-->
        <confirm-dialog
            :openDialog="dialogDelete"
            :onClicked="deleteItemConfirm"
            :onClose="closeDelete"
            toolBarColor="red darken-2"
        ></confirm-dialog>
        <!--Delete Bill-Body confirm-->
        <confirm-dialog
            :openDialog="deleteBillBodyConfirm"
            :onClicked="deleteBodyConfirm"
            :onClose="closeDeleteBody"
            toolBarColor="red darken-2"
        ></confirm-dialog>
        <!--Bill Vouchers-->
        <bill-vouchers-card
            :key="dialogBillVouchers"
            :billGuid="billGuid"
            :showDialog="dialogBillVouchers"
            @closeVouchersDialog="closeVouchersDialog"
        ></bill-vouchers-card>
        <Customer-Dialog
            :editedItem="customer"
            :editedIndex="-1"
            :dialog="customerDialog"
            :close="closeCustomerDialog"
            :refreshCustomers="refreshCustomers"
            @select-customer="selectCustomer"
        />
    </v-container>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import VoucherCard from "./VoucherCard.vue";
import BillVouchersCard from "./BillVouchersCard.vue";
import CustomerDialog from "../Customer/CustomerDialog.vue";

export default {
    components: {
        ConfirmDialog,
        VoucherCard,
        BillVouchersCard,
        CustomerDialog,
        PageHeader
    },
    props: ["bType"],
    data() {
        return {
            unitedQuantity: 0,
            quantityDialog: false,
            planGuid: null,
            plans: [],
            rowIndex: -1,
            customer: {
                customerGuid: null
            },
            customerDialog: false,
            currencyGuid: null,
            currencyValue: null,
            newButtonClicked: false,
            billGuid: null,
            dialogBillVouchers: false,
            viewVoucher: false,
            voucher: null,
            deletedBillBody: null,
            deleteBillBodyConfirm: false,
            focusedRowKey: 0,
            lastPayType: null,
            autoNavigateToFocusedRow: true,
            startEditAction: "click",
            id: this.$route.params.id,
            activePicker: null,
            date: null,
            menu: false,
            headers: [
                {
                    text: this.$t("billBody.barcode"),
                    value: "barcode"
                },
                {
                    text: this.$t("billBody.item"),
                    value: "itemGuid"
                },
                {
                    text: this.$t("billBody.price"),
                    value: "price"
                },
                {
                    text: this.$t("billBody.quantity"),
                    value: "quantity"
                },
                /*{
                    text: this.$t("billBody.totalPrice"),
                    value: "totalPrice"
                },
                 {
                    text: this.$t("billBody.discount"),
                    value: "discount"
                },
                {
                    text: this.$t("billBody.extra"),
                    value: "extra"
                }, */
                {
                    text: this.$t("billBody.netPrice"),
                    value: "netPrice"
                },
                {
                    text: this.$t("billBody.notes"),
                    value: "notes"
                },
                { text: "", value: "actions" }
            ],
            priceTypes: [
                { key: this.$t("priceTypes.0"), value: 0 },
                { key: this.$t("priceTypes.1"), value: 1 },
                { key: this.$t("priceTypes.2"), value: 2 },
                { key: this.$t("priceTypes.3"), value: 3 },
                { key: this.$t("priceTypes.4"), value: 4 },
                { key: this.$t("priceTypes.5"), value: 5 }
            ],
            payTypes: [
                { key: this.$t("payTypes.cash"), value: 1 },
                { key: this.$t("payTypes.delayed"), value: 2 }
            ],
            billType: this.bType,
            billTypes: [
                { key: 0, value: "rubbish -> used for indexing" },
                { key: 1, value: "Purchase" },
                { key: 2, value: "Sale" },
                { key: 3, value: "PurchaseReturn" },
                { key: 4, value: "SaleReturn" }
            ],
            valid: true,
            loading: true,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            billSetting: null,
            priceType: null,
            accounts: [],
            accountsContra: [],
            customers: [],
            stocks: [],
            items: [],
            currencies: [],
            billBodies: [],
            editedItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: []
            },
            defaultItem: {
                billGuid: null,
                billSettingGuid: null,
                customerGuid: null,
                accountGuid: null,
                accountContraGuid: null,
                stockGuid: null,
                billType: null,
                billDate: moment(new Date()).format("yyyy-MM-DD"),
                billNumber: null,
                currencyGuid: null,
                currencyValue: null,
                totalAmount: null,
                totalDiscount: 0,
                totalExtra: 0,
                totalNet: null,
                notes: null,
                payType: null,
                isLock: false,
                isAccept: false,
                isPaid: false,
                isMaintaince: false,
                createdDate: null,
                createdUserGuid: null,
                modifiedDate: null,
                modifiedUserGuid: null,
                branchGuid: null,
                billBodies: []
            },
            rules: [value => !!value || value == 0 || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(43)) {
            this.redirectUnauthorizedUsers();
        }
        this.loading = true;
        this.refreshData();
        this.getPlans();
    },
    watch: {
        dialogDelete(val) {
            val;
        },
        menu(val) {
            val && setTimeout(() => (this.activePicker = "DATE"));
        },
        $route(val) {
            if (
                (this.$route.name == "bill.purchaseBill" &&
                    !this.isInRole(32)) ||
                (this.$route.name == "bill.saleBill" && !this.isInRole(34)) ||
                (this.$route.name == "bill.purchaseReturnBill" &&
                    !this.isInRole(33)) ||
                (this.$route.name == "bill.saleReturnBill" &&
                    !this.isInRole(35))
            )
                this.redirectUnauthorizedUsers();

            if (val != this.$route.name) {
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.billBodies = [];
                this.id = null;
                this.refreshData();
            }
        },
        "editedItem.billBodies": {
            handler: function() {
                var mainCurrency = this.currencies.filter(function(obj) {
                    return obj.isMain == true;
                })[0];

                var billCurrency = {
                    currencyGuid: this.currencyGuid,
                    currency: {
                        currencyValue: this.currencyValue
                    }
                };

                for (
                    let index = 0;
                    index < this.editedItem.billBodies.length;
                    index++
                ) {
                    var element = this.editedItem.billBodies[index];

                    element.netPrice =
                        Number(element.price) * Number(element.quantity) +
                        Number(element.extra) -
                        Number(element.discount);
                    element.finalPrice = element.netPrice;

                    var item = null;

                    if (element.billBodyGuid != undefined) {
                        item = {
                            price:
                                element.finalPrice -
                                element.quantity * element.costPrice,
                            currencyGuid: billCurrency.currencyGuid,
                            currency: billCurrency.currency
                        };

                        element.profit = this.currencyConverter(
                            mainCurrency,
                            item
                        );
                    } else {
                        item = {
                            price:
                                element.finalPrice -
                                element.quantity * element.itemCostPrice,
                            currencyGuid: billCurrency.currencyGuid,
                            currency: billCurrency.currency
                        };

                        element.profit = this.currencyConverter(
                            mainCurrency,
                            item
                        );

                        element.costPrice =
                            element.itemCostPrice * element.quantity;
                    }
                    element.lastBuyPrice = Number(element.price);
                    element.totalPrice = element.price * element.quantity;
                }
                this.editedItem.totalAmount = this.editedItem.billBodies.reduce(
                    (a, b) => +a + +b.netPrice,
                    0
                );
            },
            deep: true
        }
    },
    methods: {
        uniteQuantity() {
            if (this.editedItem.billBodies.length > 0) {
                var quantity = this.unitedQuantity;

                this.editedItem.billBodies.forEach(element => {
                    element.quantity = quantity;
                });
            }
            this.quantityDialog = false;
        },
        openQuantityDialog() {
            this.quantityDialog = true;
        },
        getPlans() {
            axios.get("Plans/Get").then(response => {
                this.plans = response.data.data;
            });
        },
        changePlan() {
            if (this.planGuid != null || this.planGuid != undefined) {
                var selectedPlan = this.planGuid;

                var items = this.plans.find(function(obj) {
                    return obj.planGuid == selectedPlan;
                }).planItems;

                this.editedItem.billBodies = items;

                for (let i = 0; i < items.length; i++) {
                    this.itemChanged(items[i], 2);
                }
            }
        },
        currencyChange(val) {
            if (val != null || val != undefined) {
                if (this.editedItem.billGuid == null) {
                    var x = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    });

                    this.currencyValue = x[0].currencyValue;
                    this.isCurrencyMain = x[0].isMain;
                } else if (
                    this.editedItem.billGuid != null &&
                    this.currencyGuid != val
                ) {
                    var billCurrency = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == val;
                    })[0];

                    this.currencyValue = billCurrency.currencyValue;
                    this.isCurrencyMain = billCurrency.isMain;
                } else {
                    var temp = this.currencyGuid;
                    var c = this.currencies.filter(function(obj) {
                        return obj.currencyGuid == temp;
                    });

                    this.currencyValue = this.editedItem.currencyValue;
                    this.isCurrencyMain = c[0].isMain;
                }
            }
        },
        getCurrencies() {
            axios
                .get("Currencies/Get")
                .then(response => {
                    this.currencies = response.data.data;
                    this.refreshData();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        displayBillVouchers() {
            this.billGuid = this.$route.params.id;
            this.dialogBillVouchers = true;
        },
        deleteBillBody(item) {
            this.deletedBillBody = item;

            if (
                (this.deletedBillBody != undefined ||
                    this.deletedBillBody != null) &&
                (this.deletedBillBody.billBodyGuid != undefined ||
                    this.deletedBillBody.billBodyGuid != null)
            )
                this.deleteBillBodyConfirm = true;
            else this.deleteBodyConfirm();
        },
        deleteBodyConfirm() {
            var index = this.editedItem.billBodies.indexOf(
                this.deletedBillBody
            );
            this.editedItem.billBodies.splice(index, 1);
            this.closeDeleteBody();
        },
        closeDeleteBody() {
            this.deleteBillBodyConfirm = false;
        },
        pay() {
            if (this.lastPayType != this.editedItem.payType) return;
            if (this.editedItem.remainingAmount == 0) return;

            /*
                Hard Coded !!!!!!!!!!!
            */
            var voucherType = 0;

            if (
                this.editedItem.billType == "Sale" ||
                this.editedItem.billType == "PurchaseReturn"
            ) {
                voucherType = 2;
            } else if (
                this.editedItem.billType == "Purchase" ||
                this.editedItem.billType == "SaleReturn"
            ) {
                voucherType = 3;
            }

            this.voucher = {
                voucherDate: this.editedItem.billDate,
                voucherType: voucherType,
                notes: this.editedItem.notes,
                currencyGuid: this.currencyGuid,
                currencyValue: this.currencyValue,
                isPaidBill: true,
                billGuid: this.editedItem.billGuid,
                entries: [
                    {
                        accountGuid: this.editedItem.accountGuid,
                        accountContraGuid: null,
                        debit: 0,
                        credit: 0,
                        sourceType: 2,
                        sourceGuid: this.editedItem.customerGuid,
                        isHeader: true
                    },
                    {
                        accountGuid: this.editedItem.customer.accountGuid,
                        accountContraGuid: this.editedItem.accountGuid,
                        debit:
                            voucherType == 3
                                ? this.editedItem.remainingAmount
                                : 0,
                        credit:
                            voucherType == 2
                                ? this.editedItem.remainingAmount
                                : 0,
                        sourceType: 2,
                        sourceGuid: this.editedItem.customerGuid,
                        isHeader: false
                    }
                ]
            };

            this.viewVoucher = true;

            /* let routeData = this.$router.resolve({
                name: routeName,
                params: { vType: voucherType },
                query: { billVoucher: JSON.stringify(voucher) }
            });
            window.open(routeData.href, "_blank"); */
        },
        isAcceptChange() {
            if (this.editedItem.isAccept) this.editedItem.isLock = true;
        },
        totalNetCalculation() {
            this.editedItem.totalNet =
                Number(this.editedItem.totalAmount) +
                Number(this.editedItem.totalExtra) -
                Number(this.editedItem.totalDiscount);
        },
        newEntity() {
            var routeName = "";

            if (this.bType == "Purchase") routeName = "bill.purchaseBill";
            else if (this.bType == "Sale") routeName = "bill.saleBill";
            else if (this.bType == "PurchaseReturn")
                routeName = "bill.purchaseReturnBill";
            else if (this.bType == "SaleReturn")
                routeName = "bill.saleReturnBill";
            else if (this.bType == "InstallmentsSale")
                routeName = "bill.installmentsSaleBill";

            this.$router
                .push({
                    name: routeName,
                    props: { vType: this.bType }
                })
                .catch(error => {
                    error;
                });

            /*  this.newButtonClicked = true;
            this.$refs.form.reset();

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });

            this.$refs.form.resetValidation();
            this.billBodies = [];
            this.id = null;
            this.refreshData(); */
        },
        currencyConverter(bill, item) {
            if (bill.currencyGuid == item.currencyGuid)
                return Number(item.price);
            else
                return (
                    Math.round(
                        ((Number(item.price) *
                            Number(item.currency.currencyValue)) /
                            Number(bill.currencyValue) +
                            Number.EPSILON) *
                            100
                    ) / 100
                );
        },
        itemChanged: function(item, selection) {
            setTimeout(() => {
                try {
                    var index = this.editedItem.billBodies.indexOf(item);
                    var row = this.editedItem.billBodies[index];

                    if (this.currencyValue == null || this.currencyValue == 0) {
                        this.$toast.warning(
                            this.$t("error.InsertCurrencyValue")
                        );
                        this.editedItem.billBodies.splice(index, 1);
                        return;
                    }
                    var selectedItems = null;

                    if (selection == 1) {
                        selectedItems = this.items.filter(function(obj) {
                            return (
                                obj.barcode1 == row.barcode ||
                                obj.barcode2 == row.barcode ||
                                obj.barcode2 == row.barcode
                            );
                        });
                    } else {
                        selectedItems = this.items.filter(function(obj) {
                            return obj.itemGuid == row.itemGuid;
                        });
                    }

                    if (
                        selectedItems == undefined ||
                        selectedItems == null ||
                        selectedItems.length == 0
                    ) {
                        this.$toast.warning(this.$t("error.ItemNotExists"));
                        return;
                    }

                    var selectedItem = selectedItems[0];

                    var bill = {
                        currencyGuid: this.currencyGuid,
                        currencyValue: this.currencyValue
                    };

                    var mainCurrency = this.currencies.filter(function(obj) {
                        return obj.isMain == true;
                    })[0];

                    row.barcode = selectedItem.barcode1;
                    row.itemGuid = selectedItem.itemGuid;
                    row.quantity = 1;
                    row.discount = 0;
                    row.extra = 0;

                    //convert the selectedItem currency to bill currency
                    selectedItem.price = selectedItem.costPrice;

                    if (this.currencyGuid == mainCurrency.currencyGuid) {
                        row.itemCostPrice = selectedItem.price;
                    } else {
                        row.itemCostPrice = selectedItem.costPrice = this.currencyConverter(
                            bill,
                            {
                                price: selectedItem.price,
                                currencyGuid: mainCurrency.currencyGuid,
                                currency: mainCurrency
                            }
                        );
                    }

                    switch (this.priceType) {
                        case 1:
                            selectedItem.price = selectedItem.price1;
                            row.price = this.currencyConverter(
                                bill,
                                selectedItem
                            );
                            break;
                        case 2:
                            selectedItem.price = selectedItem.price2;
                            row.price = this.currencyConverter(
                                bill,
                                selectedItem
                            );
                            break;
                        case 3:
                            selectedItem.price = selectedItem.price3;
                            row.price = this.currencyConverter(
                                bill,
                                selectedItem
                            );
                            break;
                        case 4:
                            selectedItem.price = selectedItem.lastBuyPrice;

                            if (
                                this.currencyGuid == mainCurrency.currencyGuid
                            ) {
                                row.price = selectedItem.price;
                            } else {
                                row.price = this.currencyConverter(bill, {
                                    price: selectedItem.price,
                                    currencyGuid: mainCurrency.currencyGuid,
                                    currency: mainCurrency
                                });
                            }
                            break;
                        case 5:
                            selectedItem.price = selectedItem.costPrice;
                            if (
                                this.currencyGuid == mainCurrency.currencyGuid
                            ) {
                                row.price = selectedItem.price;
                            } else {
                                row.price = this.currencyConverter(bill, {
                                    price: selectedItem.price,
                                    currencyGuid: mainCurrency.currencyGuid,
                                    currency: mainCurrency
                                });
                            }
                            break;
                        default:
                            selectedItem.price = 0;
                            row.price = 0;
                            break;
                    }

                    //save the "last buy price" for selectedItem in main currency
                    bill = {
                        currencyGuid: mainCurrency.currencyGuid,
                        currencyValue: mainCurrency.currencyValue
                    };

                    if (this.currencyGuid == mainCurrency.currencyGuid) {
                        row.lastBuyPrice = selectedItem.price;
                    } else {
                        row.lastBuyPrice = this.currencyConverter(bill, {
                            price: selectedItem.price,
                            currencyGuid: mainCurrency.currencyGuid,
                            currency: mainCurrency
                        });
                    }

                    /* ///////////////////////////////////////////////Check if item exist//////////////////////////////////////////
                    var x = this.editedItem.billBodies.filter(
                        w => w.barcode == item.barcode
                    ).length;
                    if (x > 1) {
                        for (
                            let i = 0;
                            i < this.editedItem.billBodies.length;
                            i++
                        ) {
                            if (
                                this.editedItem.billBodies[i].barcode ==
                                item.barcode
                            ) {
                                this.editedItem.billBodies[i].quantity++;
                                break;
                            }
                        }
                        this.editedItem.billBodies.splice(index, 1);

                        return;
                    }
                    //////////////////////////////////////////////////////////////////////////////////////////////////////////// */

                    this.editedItem.billBodies.splice(index, 1);
                    this.editedItem.billBodies.splice(index, 0, row);
                } catch (error) {
                    console.log(error);
                }
            }, 0);
        },
        getSettingsByType() {
            axios
                .get("Bills/GetBillSettingsByType?type=" + this.bType)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.billSetting = response.data.data[0];

                        this.editedItem.billType = this.bType;
                        this.editedItem.billSettingGuid =
                            response.data.data[0].billSettingGuid;

                        this.editedItem.accountGuid = this.billSetting.accountGuid;
                        this.editedItem.accountContraGuid = this.billSetting.accountContraGuid;
                        this.editedItem.stockGuid = this.billSetting.stockGuid;
                        this.editedItem.customerGuid = this.billSetting.customerGuid;
                        this.editedItem.payType = this.billSetting.payType;
                        this.priceType = this.billSetting.priceType;

                        var temp = this.billSetting.currencyGuid;
                        var c = this.currencies.filter(function(obj) {
                            return obj.currencyGuid == temp;
                        });

                        this.currencyGuid = c[0].currencyGuid;
                        this.currencyValue = c[0].currencyValue;
                        this.isCurrencyMain = c[0].isMain;

                        var dto = {
                            billType: this.bType,
                            billSettingGuid:
                                response.data.data[0].billSettingGuid
                        };

                        axios
                            .post("Bills/GenerateBillNumber", dto)
                            .then(response => {
                                if (response.data.status == "Successful") {
                                    this.editedItem.billNumber =
                                        response.data.data;
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            });
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                });
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        deleteItem() {
            this.dialogDelete = true;
        },
        addItem() {
            this.editedItem.billBodies.push({
                quantity: 0,
                discount: 0,
                extra: 0,
                price: 0
            });
            this.rowIndex = this.editedItem.billBodies.length - 1;
        },
        removeItem(item) {
            var index = this.editedItem.billBodies.indexOf(item);
            this.editedItem.billBodies.splice(index, 1);
        },
        deleteItemConfirm() {
            axios
                .delete("Bills/DeleteBill?id=" + this.editedItem.billGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.newEntity();
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    console.log(e);
                });

            this.closeDelete();
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        refreshData() {
            try {
                axios
                    .get("Bills/NewBill?billType=" + this.bType)
                    .then(response => {
                        if (response.data.status == "Successful") {
                            //Accounts
                            this.accounts = response.data.data.accounts.filter(
                                function(obj) {
                                    return (
                                        obj.parentGuid !=
                                        "00000000-0000-0000-0000-000000000000"
                                    );
                                }
                            );

                            //Customers
                            this.customers = response.data.data.customers;

                            //Currencies
                            this.currencies = response.data.data.currencies;

                            //Stocks
                            this.stocks = response.data.data.stocks;

                            //Bill Setting
                            this.billSetting = response.data.data.billSetting;

                            this.editedItem.billType = this.bType;
                            this.editedItem.billSettingGuid = this.billSetting.billSettingGuid;

                            this.editedItem.accountGuid = this.billSetting.accountGuid;
                            this.editedItem.accountContraGuid = this.billSetting.accountContraGuid;
                            this.editedItem.stockGuid = this.billSetting.stockGuid;
                            this.editedItem.customerGuid = this.billSetting.customerGuid;
                            this.editedItem.payType = this.billSetting.payType;
                            this.priceType = this.billSetting.priceType;

                            var temp = this.billSetting.currencyGuid;

                            var c = this.currencies.filter(function(obj) {
                                return obj.currencyGuid == temp;
                            });

                            this.currencyGuid = c[0].currencyGuid;
                            this.currencyValue = c[0].currencyValue;
                            this.isCurrencyMain = c[0].isMain;

                            //Bill Number
                            this.editedItem.billNumber =
                                response.data.data.billNumber;

                            //Items
                            this.items = response.data.data.items.filter(
                                function(obj) {
                                    return obj.isGroup != true;
                                }
                            );

                            //Get Bill
                            if (this.id) {
                                axios
                                    .get("Bills/GetBills/" + this.id)
                                    .then(response => {
                                        this.editedItem = response.data.data;
                                        this.editedItem.payType = Number(
                                            this.editedItem.payType
                                        );
                                        this.editedIndex = 0;
                                        this.lastPayType = this.editedItem.payType;

                                        this.currencyValue = this.editedItem.currencyValue;
                                        this.currencyGuid = this.editedItem.currencyGuid;

                                        this.editedItem.billDate = moment(
                                            this.editedItem.billDate
                                        ).format("yyyy-MM-DD");
                                    })
                                    .catch(e => {
                                        this.$toast.error(
                                            this.$t(
                                                "AnErrorOccurredDuringTheProcess"
                                            )
                                        );
                                        console.log(e);
                                    })
                                    .finally(() => {
                                        this.loading = false;
                                    });
                            }
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex == -1) {
                        this.editedItem.billGuid =
                            "00000000-0000-0000-0000-000000000000";

                        this.editedItem.billSettingGuid = this.billSetting.billSettingGuid;
                        this.editedItem.billType = this.bType;
                    }

                    if (
                        this.editedItem.accountGuid ==
                        this.editedItem.accountContraGuid
                    ) {
                        this.$toast.error(
                            this.$t(
                                "error.AccountAndAccountContraCannotBeTheSame"
                            )
                        );
                        return;
                    }

                    if (
                        this.editedItem.billBodies == null ||
                        this.editedItem.billBodies.length == 0
                    ) {
                        this.$toast(this.$t("error.NoBillBodiesFound"));
                        return;
                    }

                    if (
                        this.editedItem.totalDiscount == undefined ||
                        this.editedItem.totalDiscount == null ||
                        this.editedItem.totalDiscount == ""
                    )
                        this.editedItem.totalDiscount = 0;

                    if (
                        this.editedItem.totalExtra == undefined ||
                        this.editedItem.totalExtra == null ||
                        this.editedItem.totalExtra == ""
                    )
                        this.editedItem.totalExtra = 0;

                    if (this.editedItem.totalAmount == 0) {
                        this.$toast.error(
                            this.$t("error.TotalAmountShouldBeGreaterThanZero")
                        );
                        return;
                    }

                    this.editedItem.currencyGuid = this.currencyGuid;
                    this.editedItem.currencyValue = this.currencyValue;

                    this.loading = true;

                    axios
                        .post("Bills/SaveBill", this.editedItem)
                        .then(response => {
                            if (response.data.status == "Successful") {
                                /*  this.editedItem = response.data.data[0];

                                this.editedItem.billDate = moment(
                                    this.editedItem.billDate
                                ).format("yyyy-MM-DD");

                                this.editedIndex = 0;
                                this.id = this.editedItem.billGuid; */

                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );

                                var routeName = "";

                                if (this.bType == "Purchase")
                                    routeName = "bill.purchaseBill";
                                else if (this.bType == "Sale")
                                    routeName = "bill.saleBill";
                                else if (this.bType == "PurchaseReturn")
                                    routeName = "bill.purchaseReturnBill";
                                else if (this.bType == "SaleReturn")
                                    routeName = "bill.saleReturnBill";
                                else if (this.bType == "InstallmentsSale")
                                    routeName = "bill.installmentsSaleBill";

                                this.$router
                                    .push({
                                        name: routeName,
                                        params: {
                                            id: response.data.data[0].billGuid
                                        },
                                        props: { vType: this.bType }
                                    })
                                    .catch(error => {
                                        error;
                                    });
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                            this.refreshData();
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            this.refreshData();
                            console.log(e);
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
        print() {
            axios
                .get("Bills/PrintBill", {
                    headers: {
                        "X-Requested-With": "XMLHttpRequest"
                    },
                    responseType: "blob",
                    params: {
                        billGuid: this.editedItem.billGuid
                    }
                })
                .then(response => {
                    if (response.status == 200) {
                        var file = new Blob([response.data], {
                            type: "application/pdf"
                        });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);
                    } else {
                        this.$toast.error("Something went wrong");
                    }
                })
                .catch(e => {
                    this.$toast.error("Something went wrong");
                    console.log(e);
                });
        },
        closeVoucher() {
            this.viewVoucher = false;
        },
        voucherSaved() {
            axios
                .get("Bills/GetBills/" + this.id)
                .then(response => {
                    this.editedItem = response.data.data;
                    this.editedItem.payType = Number(this.editedItem.payType);
                    this.editedIndex = 0;
                    this.lastPayType = this.editedItem.payType;

                    this.editedItem.billDate = moment(
                        this.editedItem.billDate
                    ).format("yyyy-MM-DD");
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                    this.viewVoucher = false;
                });
        },
        closeVouchersDialog() {
            this.dialogBillVouchers = false;
            this.voucherSaved();
        },
        lockBill(val) {
            try {
                this.loading = true;
                if (
                    this.editedItem.billGuid != null &&
                    this.newButtonClicked == false
                )
                    axios
                        .post("Bills/LockBill", {
                            billGuid: this.editedItem.billGuid,
                            isLock: val,
                            isAccept: val
                        })
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.refreshData();
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
            } catch (e) {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        acceptBill(val) {
            try {
                this.loading = true;
                if (
                    this.editedItem.billGuid != null &&
                    this.newButtonClicked == false
                )
                    axios
                        .post("Bills/AcceptBill", {
                            billGuid: this.editedItem.billGuid,
                            isLock: val,
                            isAccept: val
                        })
                        .then(response => {
                            if (response.data.status == "Successful") {
                                this.refreshData();
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
            } catch (e) {
                this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        closeCustomerDialog() {
            this.customerDialog = false;
        },
        refreshCustomers() {
            this.loading = true;
            axios
                .get("Customers/Get")
                .then(response => {
                    this.customers = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        selectCustomer(customer) {
            try {
                this.editedItem.customerGuid = customer.customerGuid;
            } catch (error) {
                console.log(error);
            }
        },
        setIndex(item) {
            var index = this.editedItem.billBodies.indexOf(item);
            this.rowIndex = index;
        },
        getItemName(itemGuid) {
            if (itemGuid != null && itemGuid != undefined)
                return this.items.find(x => x.itemGuid == itemGuid).itemName;
            else return "";
        }
    }
};
</script>
<style>
#data-grid-demo {
    min-height: 700px;
}
.myTable {
    border: 1px solid #bdbdbd !important;
}

.myTable th,
.myTable td {
    border: 1px solid #bdbdbd !important;
    border-collapse: collapse;
    text-align: center;
    text-align-last: center;
    justify-content: center;
    padding: 0rem !important;
    overflow: hidden !important;
}

.options {
    margin-top: 20px;
    padding: 20px;
    background: #f5f5f5;
}
.options .caption {
    font-size: 18px;
    font-weight: 500;
}
.option {
    margin-top: 10px;
}
.option > span {
    width: 120px;
    display: inline-block;
}
.option > .dx-widget {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    max-width: 350px;
}
#gridContainer {
    height: 440px;
}
</style>
