<template>
    <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-card>
                <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                        <span class="">{{
                            (editedIndex === -1 ? $t("add") : $t("edit")) +
                                " " +
                                $t("customer.customer")
                        }}</span>
                    </v-card-title>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    v-model="editedItem.fullName"
                                    :rules="rules"
                                    :label="$t('customer.fullName')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.surname"
                                    :label="$t('customer.surname')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select
                                    outlined
                                    dense
                                    hide-details
                                    persistent-hint
                                    :rules="rules"
                                    v-model="editedItem.customerType"
                                    :items="customerTypes"
                                    item-text="key"
                                    :label="$t('customer.customerType')"
                                ></v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.phone"
                                    :label="$t('customer.phone')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.email"
                                    :label="$t('customer.email')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    v-model="editedItem.customerGroup"
                                    :rules="rules"
                                    :label="$t('customer.customerGroup')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.address"
                                    :label="$t('customer.address')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.city"
                                    :label="$t('customer.city')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    v-model="editedItem.notes"
                                    :label="$t('customer.notes')"
                                ></v-text-field>
                            </v-col>
                            <v-col
                                v-if="
                                    customerFiles != undefined &&
                                        (customerFiles != null) &
                                            (customerFiles.length > 0)
                                "
                                cols="12"
                                md="12"
                            >
                                <v-row>
                                    <v-col cols="12"
                                        ><p class="black--text">
                                            {{ $t("customer.files") }}
                                        </p></v-col
                                    >
                                    <v-col cols="12">
                                        <v-card
                                            class="overflow-y-auto"
                                            max-height="300"
                                            elevation="0"
                                        >
                                            <v-card-text>
                                                <v-row>
                                                    <v-col
                                                        v-for="file in customerFiles"
                                                        :key="file.name"
                                                        cols="12"
                                                        md="4"
                                                    >
                                                        <Download-File
                                                            :iconWidth="50"
                                                            :fileSizeFormat="
                                                                file.size
                                                            "
                                                            :onDelete="
                                                                deleteFile
                                                            "
                                                            :onClose="
                                                                closeDeleteFile
                                                            "
                                                            :file="file"
                                                            :fileUrl="file.path"
                                                        ></Download-File>
                                                    </v-col> </v-row
                                            ></v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    v-model="currFiles"
                                    small-chips
                                    show-size
                                    multiple
                                    hide-details
                                    @click:clear="clearFiles"
                                    @change="inputChanged"
                                >
                                    <template
                                        v-slot:selection="{
                                            text
                                        }"
                                    >
                                        <v-chip
                                            small
                                            text-color="white"
                                            color="#295671"
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary darken-1 white--text"
                        @click="save"
                        :disabled="!valid"
                        :min-width="100"
                        :loading="loading"
                    >
                        <v-icon>mdi-content-save-outline</v-icon>
                        {{ $t("save") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="red" @click="closeDialog">
                        {{ $t("cancel") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
        <!--Delete file confirm-->
        <confirm-dialog
            :openDialog="deleteFileDialog"
            :onClicked="deleteFileConfirm"
            :onClose="closeDeleteFile"
            toolBarColor="red darken-2"
        ></confirm-dialog>
    </v-dialog>
</template>
<script>
import axios from "axios";
import DownloadFile from "../../components/downloadFile.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
    components: { DownloadFile, ConfirmDialog },
    props: ["editedItem", "editedIndex", "dialog", "close", "refreshCustomers"],
    data() {
        return {
            customerFiles: [],
            deletedFile: null,
            deleteFileDialog: false,
            currFiles: [],
            files: [],
            valid: true,
            loading: true,
            dialogDelete: false,
            customerTypes: [
                {
                    key: this.$t("customerTypes.1"),
                    value: 1
                },
                {
                    key: this.$t("customerTypes.2"),
                    value: 2
                },
                {
                    key: this.$t("customerTypes.3"),
                    value: 3
                },
                {
                    key: this.$t("customerTypes.4"),
                    value: 4
                }
            ],
            defaultAccounts: [],
            defaultItem: {
                customerGuid: "",
                accountGuid: "",
                customerType: 0,
                fullName: "",
                surname: "",
                phone: "",
                email: "",
                address: "",
                city: "",
                notes: "",
                fileUrl: "",
                files: []
            },
            accounts: [],
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(22)) {
            this.loading = true;
            this.refreshTable();
            if (
                this.editedItem.customerGuid != undefined &&
                this.editedItem.customerGuid != null &&
                this.editedItem.customerGuid !=
                    "00000000-0000-0000-0000-000000000000" &&
                this.editedItem.customerGuid != ""
            ) {
                this.getCustomerFiles(this.editedItem.customerGuid);
            }
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.closeDialog();
        }
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.currFiles = [];
            this.files = [];
            this.close();
        },
        refreshTable() {
            axios
                .get("AccountsTree/Get")
                .then(response => {
                    this.accounts = response.data.data.filter(function(obj) {
                        return (
                            obj.parentGuid !=
                            "00000000-0000-0000-0000-000000000000"
                        );
                    });
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });

            axios
                .get("DatabaseSettings/Get")
                .then(response => {
                    var data = response.data.data.systemSettings;
                    this.defaultAccounts = data.filter(
                        x => x.group == "DefaultAccounts"
                    );
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (this.editedIndex == -1) {
                        this.editedItem.customerGuid =
                            "00000000-0000-0000-0000-000000000000";
                    }

                    switch (this.editedItem.customerType) {
                        case 1:
                            this.editedItem.accountGuid = this.defaultAccounts.find(
                                x => x.key == "Client"
                            ).value;
                            break;
                        case 2:
                            this.editedItem.accountGuid = this.defaultAccounts.find(
                                x => x.key == "Supplier"
                            ).value;
                            break;
                        case 3:
                            this.editedItem.accountGuid = this.defaultAccounts.find(
                                x => x.key == "Both"
                            ).value;
                            break;
                        case 4:
                            this.editedItem.accountGuid = this.defaultAccounts.find(
                                x => x.key == "Student"
                            ).value;
                            break;

                        default:
                            break;
                    }

                    var data = new FormData();

                    var validFiles = true;

                    for (let i = 0; i < this.files.length; i++) {
                        var file = this.files[i];
                        if (file != null && file.size <= 2097152) {
                            data.append("files", file);
                        } else {
                            validFiles = false;
                            break;
                        }
                    }

                    for (var key in this.editedItem) {
                        if (this.editedItem[key])
                            data.append(key, this.editedItem[key]);
                    }

                    if (!validFiles)
                        this.$toast.error(
                            this.$t("error.FileSizeMustBeLessThan2MegaBytes")
                        );
                    else {
                        this.loading = true;
                        axios
                            .post("Customers/Save", data)
                            .then(response => {
                                if (response.data.status == "Successful") {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );

                                    this.refreshCustomers();
                                    this.$emit(
                                        "select-customer",
                                        response.data.data
                                    );

                                    this.files = [];
                                    this.currFiles = [];

                                    this.closeDialog();
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                                this.refreshTable();
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                this.refreshTable();
                                console.log(e);
                            })
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        inputChanged() {
            this.files = [...this.currFiles, ...this.files];
        },
        getCustomerFiles(id) {
            axios.get("Customers/GetCustomerFiles?id=" + id).then(response => {
                this.customerFiles = response.data.data;
            });
        },
        deleteFileConfirm() {
            axios
                .delete(
                    "Customers/DeleteCustomerFile?fileName=" + this.deletedFile
                )
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.getCustomerFiles(this.editedItem.customerGuid);
                        this.refreshCustomers();
                        this.deleteFileDialog = false;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshTable();
                    this.deleteFileDialog = false;
                    console.log(e);
                });
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
            this.currFiles = [];
            this.files = [];
        },
        downloadFile(file) {
            window.open(file.path);
        },
        clearFiles() {
            try {
                this.files = [];
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
